<template>
    <v-container class="pt-8">
        <h1 class="display-1">Avís Legal</h1>
        <v-card v-for="paragraph in paragraphs"
                class="pa-5 mt-4"
        >
            <v-card-title class="headline">{{paragraph.title}}</v-card-title>
            <v-card-text class="title font-weight-regular">
                <template v-for="line in paragraph.text.split('\n')">{{line}}<br></template>
            </v-card-text>
        </v-card>

    </v-container>
</template>

<script>
export default {
  name: 'Cookies',
  data: () => ({
    paragraphs: [
      {
        title: 'Avís Legal',
        text: `Aquest Avís Legal té l'objectiu d'informar-te dels teus drets i obligacions com a usuari d'aquest lloc web. Aquí trobaràs tota la informació que necessites sobre aquest lloc web, la seva activitat, les dades personals que recapta i la seva finalitat, així com les normes d'ús que regulen l'ús d'aquesta web.
En el moment en què accedeixis a aquest lloc web https://examenselectivitat.cat, assumeixes la condició d'usuari, per la qual cosa el contingut d'aquest Avís Legal t'afecta directament. Per això, és important que ho llegeixis per a dissipar qualsevol dubte que puguis tenir i tenir coneixement de causa sobre les condicions que estàs acceptant.
Per a començar, has de saber que aquest lloc web compleix amb les normatives vigents en matèria de protecció de dades, amb l'objectiu d'aportar-te les garanties, la seguretat i la transparència que, com a usuari et corresponen, a l'hora d'utilitzar aquesta web.
El *RGPD (Reglament (UE) 2016/679 del Parlament Europeu i del Consell de 27 d'abril de 2016 relatiu a la protecció de les persones físiques) que és la nova normativa de la Unió Europea que unifica la regulació del tractament de les dades personals en els diferents països de la UE.
La *LOPD (Llei orgànica 15/1999, de 13 de desembre, de Protecció de Dades de Caràcter Personal i Reial decret 1720/2007, de 21 de desembre, el Reglament de desenvolupament de la *LOPD) que regula el tractament de les dades personals i les obligacions que hem d'assumir els responsables d'una web o un blog a l'hora de gestionar aquesta informació.
La *LSSI (Llei 34/2002, d'11 de juliol, de Serveis de la Societat de la Informació i Comerç Electrònic) que regula les transaccions econòmiques mitjançant mitjans electrònics, com és el cas d'aquest blog.
 `
      },
      {
        title: 'Normes d\'accés al lloc web',
        text: `Com a usuari de la nostra web, també tens una sèrie d'obligacions:

No podràs utilitzar aquest lloc web per a la realització d'activitats contràries a les lleis, a la moral, a l'ordre públic i, en general, a fer un ús conforme a les condicions establertes en el present Avís Legal.
No podràs realitzar activitats publicitàries o d'explotació comercial remetent missatges que utilitzin una identitat falsa.
Tu seràs l'únic responsable de la veracitat i exactitud dels continguts que introdueixis en aquesta web i de les dades personals que ens facilitis amb les finalitats previstes en la nostra Política de Privacitat.
També seràs l'únic responsable de la realització de qualsevol mena d'actuació il·lícita, lesiva de drets, nociva i/o perjudicial en els llocs de tercers als quals et puguem derivar des d'aquest lloc web per al desenvolupament de la nostra activitat.
`
      }
    ]
  })
}
</script>
